import React, { useState } from 'react';
import { Announcement } from '../../model/Stock';
import EditAnnouncement, { IAnnouncementsMethods } from './EditAnnouncement';
import { useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';
import styled from 'styled-components'
import AnnouncementTable from './AnnouncementTable';

interface IProps {
    syncInProgress?: boolean;
    onSavedAnnouncement: () => void;
    resyncAnnouncements?: () => Promise<boolean>;
}




const Style = styled.div`
table {
    margin: 0;
}
`

const Announcements = React.forwardRef<any, IProps>((props:IProps, ref) => {
  
  const editAnnouncementPage = React.createRef<IAnnouncementsMethods>();
  const stock = useSelector((state:IGlobalState) => state.currentStock)
    
  useImperativeHandle(ref, ():IAnnouncementsMethods => ({

    save() {
          if(editAnnouncementPage.current)
            return editAnnouncementPage.current.save();
          else{
            alert('Nothing to Save')
            return Promise.resolve(false);
          }
            
    }

  }));

  
    const [editingAnnouncement, setEditingAnnouncement] = useState<Announcement | null>();
    const [editing, setEditing] = useState<boolean>(false);

    

    const onAnnouncementEdit = (announcement:Announcement|null) => {
        setEditingAnnouncement(announcement);
        setEditing(true)
    }



    const onEditClose = () => {
        setEditingAnnouncement(null);
        setEditing(false)
    }

    

    

    return <Style className="padding">
        {editing && <EditAnnouncement
            ref={editAnnouncementPage}
            onClose={onEditClose}
            onSavedAnnouncement={props.onSavedAnnouncement}
            announcement={editingAnnouncement!}
            ticker={stock.ticker}
            exchange={stock.exchange}
            />}

        {!editing && <AnnouncementTable onAnnouncementEdit={onAnnouncementEdit} resyncAnnouncements={props.resyncAnnouncements} syncInProgress={props.syncInProgress}/>}

    </Style>
});

export default Announcements