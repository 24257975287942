import React, { useState } from 'react';
import { Announcement } from '../../model/Stock';
import api, { getCombinedTicker } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';
import { HasAdminRole, IsAdminOrHasRole } from '../../model/Roles';
import * as Roles from '../../model/Roles';
import ConfirmEx from '../ConfirmEx';
import AlertEx from '../AlertEx';
import { startSetStock } from '../../actions/CurrentStock';
import { FaCaretDown } from 'react-icons/fa';


interface IProps {
    announcement: Announcement;
    onAnnouncementEdit:(announcement:Announcement)=>void;
}

const AnnouncementMenu: React.FC<IProps> = (props: IProps) => {

    const user = useSelector((state: IGlobalState) => state.user)
    const stock = useSelector((state: IGlobalState) => state.currentStock)
    const dispatch = useDispatch();
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    
    const toggleMenu = (): void => {
        setMenuVisible(!menuVisible)
    }
    

      const onDelete = async (announcement:Announcement) : Promise<void> => {
        
                
        if (await ConfirmEx('You are about to delete an announcement for ' + stock.ticker)) {
            let result = await api().deleteAnnouncement(getCombinedTicker(stock), announcement);
            console.log('result', result)
            if(result.success){
                window.location.reload();
            }else{
                alert(`Error deleting announcement: ${result.errorMessage}`)
            }
            
        }
    }

    const sendConfirmationEmail = async (ann: Announcement): Promise<void> => {
        const send = await ConfirmEx(`Send the announcement  confirmation email to  ${stock.confirmationEmail}`);
        if(send){
            let apiResult = await api().sendConfirmationAnnouncementEmail(getCombinedTicker(stock), ann)

            if (apiResult.success) {
                
                AlertEx('Sent!');
            }
            else {
                AlertEx(apiResult.errorMessage);
            }
        }
    }

    const onSendEmail = async (ann: Announcement): Promise<void> => {

        let sendOverride = false;
        if (ann.sent) {
            const result:boolean = await ConfirmEx('You have already sent this announcement! Please confirm you wish to re-send')
            if (!result)
                return;
            sendOverride = true;
        }
        
        let sendAnnouncement = false;
        if(!sendOverride){
            sendAnnouncement = await ConfirmEx('You are manually sending an announcement for ' + stock.ticker);
        }
        
        if (sendAnnouncement || sendOverride) {

            const combinedTicker = getCombinedTicker(stock);
            let apiResult = await api().sendAnnouncement(combinedTicker, ann, sendOverride)

            if (apiResult.success) {
                // const newStock = { ...stock };
                // let sentAnnouncement = stock.announcements.find(x => x.headline === ann.headline && x.date === ann.date);
                // sentAnnouncement!.sent = true;
                // setStock(newStock);
                dispatch(startSetStock(combinedTicker))
                AlertEx('Sent!');
            }
            else {
                AlertEx(apiResult.errorMessage);
            }
        }
    }

      const onAnnouncementAction = async (announcement:Announcement, action:AnnouncementAction) =>{
        if(action==='delete'){
            await onDelete(announcement)
        }else if(action==='sendEmail'){
            await onSendEmail(announcement)
        } else if(action==='sendConfirmationEmail'){
            await sendConfirmationEmail(announcement)
        } else if(action === 'edit'){
            await props.onAnnouncementEdit(announcement);
        }
    }
    return <div className="announcement-row__menu">
    <button className="announcement-actions-button" onClick={toggleMenu}>
        <FaCaretDown /></button>

    { menuVisible && <>
    
    <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "edit")}>Edit</button>
    { IsAdminOrHasRole(user, stock.id, Roles.SendMailchimp) && <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "sendEmail")}>Send Email</button>}
    { HasAdminRole(user) && <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "sendConfirmationEmail")}>Send Confirmation Email</button>} 
    {!props.announcement.deletedOn && <button className="button-sm" onClick={(e) => onAnnouncementAction(props.announcement, "delete")}>Delete</button>}
</>}
    
</div>
}

export default AnnouncementMenu;

export type AnnouncementAction = "delete" | "edit" | "sendEmail" | "sendConfirmationEmail"