import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IGlobalState } from '../../model/IGlobalState';
import EditAnnouncementComments from './EditAnnouncementComments';
import * as Roles from '../../model/Roles';
import useAsyncEffect from 'use-async-effect';
import { loading } from '../../actions/Loading';
import Announcements from '../stock/Announcements';
import { IAnnouncementsMethods } from '../stock/EditAnnouncement';
import { startSetStock } from '../../actions/CurrentStock';
import Tabs from '../Tabs';
import Mailchimp, { IMailChimpMethods } from '../stock/Mailchimp';
import AnimatedSaveButton from '../AnimatedSaveButton';
import { uppercaseFirstLetter } from '../../util/util';
import Config, { IConfigMethods } from '../stock/Config';

interface IProps{
    match: { params: {  combinedTicker:string }};
    tab?:string;
}

const ConfigTabName:string = 'Config';
const CommentsTabName:string = 'Comments';
const AnnouncementsTabName:string = 'Announcements';
const MailchimpTabName:string = 'Mailchimp';

const Wrapper = styled.div`

`;

const ClientStockItem: React.FC<IProps> = (props:IProps) => {
    const dispatch = useDispatch();
    const ticker = props.match.params.combinedTicker
    const user = useSelector((state: IGlobalState) => state.user)
    const stock = useSelector((state: IGlobalState) => state.currentStock)
    const [ activeTab, setActiveTab ] = useState(uppercaseFirstLetter(props.tab) || ConfigTabName);
    const mailchimpPage = React.createRef<IMailChimpMethods>();
    const configPage = React.createRef<IConfigMethods>();
    

    useAsyncEffect(async (isMounted:()=>boolean)=> {
        dispatch(loading(true));
        dispatch(startSetStock(ticker))
        dispatch(loading(false));
      }, []);

      

      
      
      const hasRole = (r:string) => user.stockRoles[stock.id].includes(r)
     

      const announcementsPage = React.createRef<IAnnouncementsMethods>();
      const onSavedAnnouncement = async () => { 
            dispatch(startSetStock(ticker))
        }

    const save = async ()=>{
        let success:boolean = false;
        if(activeTab === AnnouncementsTabName){
            success = await announcementsPage.current!.save();
        }else if(activeTab === MailchimpTabName){            
            success = await mailchimpPage.current!.save();
        }else if(activeTab === ConfigTabName){            
            success = await configPage.current!.save();
        }
        return Promise.resolve(success);
    }

    

    return <Wrapper>
    { stock && <div>

        <div className="space-between">
                <div className="space-between">
                <h1> { ticker } </h1>
                
                </div>
                
                {<AnimatedSaveButton onClick={save}  />} 
            </div>

        <Tabs onTabChanged={(t:string)=>setActiveTab(t)} activeTab={activeTab}>
    
        
        { hasRole(Roles.ViewConfig) && <div data-label={ConfigTabName}>
                        <Config    
                        key={stock.id}                    
                        id={stock.id}                    
                        ticker={stock.ticker} 
                        exchange={stock.exchange}
                        
                            ref={configPage}    
                        />
                    </div>}

    { hasRole(Roles.EditAnnouncements) && <div data-label={AnnouncementsTabName}>
                        <Announcements
                            syncInProgress={false}
                            ref={announcementsPage}
                            onSavedAnnouncement={onSavedAnnouncement}
                        />
                    </div>}

                    {hasRole(Roles.EditMailchimp) && <div data-label={MailchimpTabName}>
                        <Mailchimp 
                        ticker={ticker} 
                        apiKey={stock.apiKey}
                        stockId={stock.id}
                        ref={mailchimpPage}
                        />
                    </div>}


                    { hasRole(Roles.ReplyToComment) && <div data-label={CommentsTabName}>
                         <EditAnnouncementComments combinedTicker={ticker}
                         
                         />
    </div>}

        </Tabs>
    </div>
    
    
    }
    




</Wrapper>
}

export default ClientStockItem

