import React from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';
import AnimatedSaveButton from '../AnimatedSaveButton';
import { Announcement } from '../../model/Stock';
import SortableTable from '../table/SortableTable';
import api from '../../api/api';
import { toSydneyDate } from '../../util/util';
import { FaExclamation, FaExternalLinkAlt, FaMailchimp } from 'react-icons/fa';
import moment from 'moment';
import AnnouncementMenu from './AnnouncementMenu';

interface IProps {
    syncInProgress?: boolean;
    resyncAnnouncements?: () => Promise<boolean>;
    onAnnouncementEdit:(announcement:Announcement|null)=>void;
}


const DateColumn = 'Date';
const HeadlineColumn = 'Headline';
const ViewsColumn = 'Views';
const CommentsColumn = 'Comments';
const ActionsColumn = 'Actions';

const getHeaders = () =>  {
    return [
        DateColumn,       
        HeadlineColumn,
        ViewsColumn,    
        CommentsColumn,       
        ActionsColumn,   
    ];   
}

const getExternalLink = (announcement:Announcement) : string => {          
    const localPath:string = announcement.localPath!;
    if (!(announcement.localPath||"").startsWith("/api/")){
        return announcement.remotePath!;
    }
    
    if(window.location.hostname !== 'localhost'){
        let parts = window.location.hostname.split('.');
        parts.shift();
        return `https://${parts.join('.')}${localPath}`;            
    }
    return api().getUrl(localPath);              
  }

  const getTitle = (announcement: Announcement): string => {
    let title = '';
    for (let meta of announcement.sendHistory || []) {
        const date = moment(meta.timestamp);
        title += `Sent to Mailchimp by ${meta.emailAddress} on ${date.format('D MMM YYYY')} at ${date.format('h:mm A')}\n`
    }
    return title;
}

const AnnouncementTable: React.FC<IProps> = (props: IProps) => {
    const announcements = useSelector((state: IGlobalState) => state.currentStock.announcements || []);

    const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
        if(columnName===DateColumn){        
            return toSydneyDate(cell)     
        }
        else if(columnName===HeadlineColumn){
            const announcement = cell as Announcement;
            const headlineChanged = announcement.headlineOriginal && announcement.headline !== announcement.headlineOriginal
            return <div>
                    <div className="announcement-row__headline">
                        <span className={!!announcement.deletedOn ? " announcement-row__headline-text-deleted" : ""}>{announcement.headline}</span>
    
                        <a href={getExternalLink(announcement)}
                            rel="noopener noreferrer"
                            className="announcement-row__pdflink"
                            target="_blank">
                            <FaExternalLinkAlt size={12} color="grey" />
                        </a>
                        {announcement.deletedOn && <span className=' announcement-row__deleted'>Deleted on: {moment(announcement.deletedOn).tz('Australia/Sydney').format('D MMM YYYY h:mm A')}</span>}

                        { announcement.sent && <span className="announcement-row__sent" title={getTitle(announcement)}> <FaMailchimp />  </span> }

                        {announcement.priceSensitive &&
                            <span className="announcement-row__price-sensitive" title="Price Sensitive"><FaExclamation /></span>
                        }
                    </div>
                    
                    
                    {headlineChanged && <div className="announcement-row__headlineOriginal">Originally: {announcement.headlineOriginal}</div>}
                </div>  
        }else if(columnName===ActionsColumn){
            const announcement = cell as Announcement;
            return <AnnouncementMenu announcement={announcement}  onAnnouncementEdit={props.onAnnouncementEdit}/>
            
        }
        return null;
    }

    const data:any[][] = [];
        for(let announcement of announcements){
            const row = [
                announcement.date,
               announcement,
               announcement.hits||'',               
               announcement.numComments || '',               
               announcement
            ]
            data.push(row)
        }

    return (
        <div>
            { !props.syncInProgress && <div className="row-reverse">
                <button style={{marginLeft:'10px'}} onClick={()=>props.onAnnouncementEdit(null)}>Add Announcement</button>
                { props.resyncAnnouncements && <AnimatedSaveButton onClick={props.resyncAnnouncements} startingText="Resync"  /> } 
            </div>}
            
            
            {/* { announcements.map((x: Announcement, index: number) => <AnnouncementItem key={index} announcement={x} onAnnouncementEdit={onAnnouncementEdit}  />) } */}

            <div className="horizontal">
                <p>{announcements.length} Announcements</p>
                <p style={{fontSize:'0.7rem', fontStyle:'italic'}}>Time is Sydney EDST</p>
            </div>

            <SortableTable headers={getHeaders()} data={data} cellFormatter={cellFormatter} sortDirection="desc"  />
        </div>
    )
}

export default AnnouncementTable;
